// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAVBtTakvEy1h3ElnSJOfkb_Fk77Hpf9EM",
  authDomain: "gfm-clients.firebaseapp.com",
  projectId: "gfm-clients",
  storageBucket: "gfm-clients.appspot.com",
  messagingSenderId: "285549024645",
  appId: "1:285549024645:web:7b8912210600cd5a8620ec",
  measurementId: "G-W31ZQX4TLG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;