import './Top.css';
import { BsFacebook, BsTwitter, BsYoutube, BsGoogle } from "react-icons/bs";

export default function Top() {
  return (
    <div className="Top">
      <a style={{fontSize: '.9rem'}} href='mailto:service@pestterminatorinc.com'>service@pestterminatorinc.com</a>
      <ul>
        <li><a href="https://www.facebook.com/Pest-Terminator-1584153525234362/" target="_blank"><BsFacebook /></a></li>
        <li><a href='https://twitter.com/Pest_Terminator' target='_blank'><BsTwitter /></a></li>
        <li><a href='https://www.youtube.com/channel/UCjPLIFIfKW5toB3uxzGi8OA' target='_blank'><BsYoutube /></a></li>
      </ul>
    </div>
  );
}