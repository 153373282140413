import pestTerm from "./images/pest-term.png";
import goForth from "./images/go-forth-site-logo.png";
import "./Main.css";

export default function Main() {
  return <div className="Main">
    <a href='https://go-forth.com/'>
   <img src={pestTerm} alt="Pest Terminator" />
   <h1>is now a part of the</h1>
    <img src={goForth} alt="Go-Forth Pest Control" />
    <h1>family!</h1></a>
    </div>;
}
